import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FourHorsemen from "../../images/four_horsemen_of_the_non_apocalypse.png"
import LinkExternal from "../../components/link_external"
import Routes from "../../routes"
import { useImpression } from "../../analytics/utils"

const quotesSection = (
  <>
    <h2>Best Sam Harris Quotes</h2>
    <blockquote>
      “We must not overlook the fact that a significant percentage of the
      world's Muslims believe that the men who brought down the World Trade
      Center are now seated at the right hand of God, amin 'rivers of purest
      water, and rivers of milk forever fresh; rivers of wine delectable to
      those that drink it, and rivers of clearest honey' (47:15). These
      men&mdash;who slit the throats of stewardesses and delivered young couples
      with their children to their deaths at five hundred miles per
      hour&mdash;are at present being 'attended by boys graced with eternal
      youth' in a 'kingdom blissful and glorious.'”
      <br />
      <span style={{ float: "right" }}>
        &mdash; The End of Faith: Religion, Terror, and the Future of Reason
        (127)
      </span>
      <br />
    </blockquote>
    <blockquote>
      “Consider it: every person you have ever met, every person will suffer the
      loss of his friends and family. All are going to lose everything they love
      in this world. Why would one want to be anything but kind to them in the
      meantime?”
      <br />
      <span style={{ float: "right" }}>
        &mdash; The End of Faith: Religion, Terror, and the Future of Reason
      </span>
      <br />
    </blockquote>
    <blockquote>
      “If you think that it would be impossible to improve upon the Ten
      Commandments as a statement of morality, you really owe it to yourself to
      read some other scriptures. Once again, we need look no further than the
      Jains: Mahavira, the Jain patriarch, surpassed the morality of the Bible
      with a single sentence: 'Do not injure, abuse, oppress, enslave, insult,
      torment, torture, or kill any creature or living being.' Imagine how
      different our world might be if the Bible contained this as its central
      precept. Christians have abused, oppressed, enslaved, insulted, tormented,
      tortured, and killed people in the name of God for centuries, on the basis
      of a theologically defensible reading of the Bible.”
      <br />
      <span style={{ float: "right" }}>
        &mdash; Letter to a Christian Nation
      </span>
      <br />
    </blockquote>
    <blockquote>
      “The president of the United States has claimed, on more than one
      occasion, to be in dialogue with God. If he said that he was talking to
      God through his hairdryer, this would precipitate a national emergency. I
      fail to see how the addition of a hairdryer makes the claim more
      ridiculous or offensive.”
      <br />
      <span style={{ float: "right" }}>
        &mdash; Letter to a Christian Nation
      </span>
      <br />
    </blockquote>
    <hr />
    <p>
      Read one of our other articles about the{" "}
      <Link to={Routes.SEPT_11}>September 11, 2001 attacks</Link>.
    </p>
  </>
)

const books = (
  <div
    style={{
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
    }}
  >
    {[
      {
        description: (
          <>
            Sam Harris has an incredibly engaging book called{" "}
            <i>The End of Faith</i>. He starts it with an in depth story of a
            terrorist attack. The attention to detail that goes into it
            immediately grabs your attention. When thinking about religion you
            usually are not considering things like terrorist attacks, you're
            probably thinking about everyday church-goers. But Harris starts by
            focusing on an extremely serious event. Check it out and see for
            yourself.
          </>
        ),
        title: "The End of Faith: Religion, Terror, and the Future of Reason",
        year: 2004,
        link: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=colinharris05-20&marketplace=amazon&region=US&placement=0393327655&asins=0393327655&linkId=cf0d748b57a568afad397bc30036addf&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff",
      },
      {
        title: "Letter to a Christian Nation",
        description: (
          <>
            Another one of his books, <i>Letter to a Christian Nation</i>,
            considers the ethics and morality of Christianity. It digs into the
            idea of a blood sacrifice, and why things like Christ's crucifixion
            doesn't seem to have any connection to a 'loving' god.
          </>
        ),
        year: "2006",
        link: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=colinharris05-20&language=en_US&marketplace=amazon&region=US&placement=B000JMKTNM&asins=B000JMKTNM&linkId=c700bb3f80813ce8caa192868e5ac381&show_border=true&link_opens_in_new_window=true",
      },
      {
        title: "Lying",
        year: "2011",
        link: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=colinharris05-20&language=en_US&marketplace=amazon&region=US&placement=B00G1SRB6Q&asins=B00G1SRB6Q&linkId=33dc54072f480f2c44ec8489ecddc452&show_border=true&link_opens_in_new_window=true",
      },
      {
        title: "Free Will",
        year: "2012",
        link: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=colinharris05-20&language=en_US&marketplace=amazon&region=US&placement=B006IDG2T6&asins=B006IDG2T6&linkId=34bb3b87ddbbf0eabfb366613679f94f&show_border=true&link_opens_in_new_window=true",
      },
      {
        title: "The Moral Landscape",
        year: "2010",
        link: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=colinharris05-20&language=en_US&marketplace=amazon&region=US&placement=B003V1WT72&asins=B003V1WT72&linkId=bad14a4822b1a1f7b1a7b150ba84a8ae&show_border=true&link_opens_in_new_window=true",
      },
      {
        title: "Islam and the Future of Tolerance: A Dialogue",
        year: "2015",
        link: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=colinharris05-20&language=en_US&marketplace=amazon&region=US&placement=B0163EHLRQ&asins=B0163EHLRQ&linkId=b4dd3f31e68680011d53263932c0c820&show_border=true&link_opens_in_new_window=true",
      },
    ].map(({ description, title, year, link }) => (
      <div key={title} style={{ padding: 10 }}>
        <h3>{title}</h3>
        <p>{description}</p>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <iframe
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            scrolling="no"
            src={link}
            style={{ width: 120, height: 240 }}
            title="Letter to a Christian Nation"
          ></iframe>
        </div>
      </div>
    ))}
  </div>
)

const SamHarris = ({ location }) => {
  useImpression("SamHarris")
  return (
    <Layout location={location}>
      <Seo
        title="Sam Harris"
        description="Sam Harris - Waking up the world to atheism, agnosticism, morality, and actual spirituality. Sam is well known for his views on Christianity and Islam."
      />
      <h1>The Best of Sam Harris and the Making Sense Podcast</h1>
      <div
        style={{
          float: "right",
          padding: 20,
          margin: 30,
          marginTop: 50,
          border: "solid black 1px",
          borderRadius: 5,
          textAlign: "center",
        }}
      >
        <h3 style={{ marginBottom: 10 }}>Follow Sam</h3>
        <div>
          <LinkExternal to="https://samharris.org/">samharris.org</LinkExternal>
        </div>
        <div>
          <LinkExternal to="https://twitter.com/SamHarrisOrg">
            @SamHarrisOrg
          </LinkExternal>
        </div>
      </div>
      <h2>Who is he?</h2>
      <p>
        Sam Harris is an American author, a podcast creator, and is know around
        the world for his criticisms of religion. He earned a Ph.D in cognitive
        neuroscience from the University of California, Los Angeles. He is
        interested in all sorts of ideas from things like religion, meditation,
        spirituality, ethics, morality, philosophy, to terrorism, AI (artificial
        intelligence), and politics.
      </p>
      <p>
        Alongside <Link to={Routes.HITCHENS}>Christopher Hitchens</Link>,
        Richard Dawkins, and Daniel Dennett, Sam Harris makes the fourth of the
        "Four Horsemen of the Non-Apocalypse".
      </p>
      <div className="text-center">
        <img
          src={FourHorsemen}
          alt="The Four Horsemen of the Non-Apocalypse: David Demmett, Richard Dawkins, Christopher Hitchens, Sam Harris"
        />
      </div>
      <h2>Waking Up Podcast</h2>
      <p>
        Sam has participated in many intense debates and great discussions, and
        seems to always maintain a relatively calm and eased manner. He has a
        meditation app, and lots of resources at{" "}
        <LinkExternal to="https://wakingup.com">wakingup.com</LinkExternal> and{" "}
        <LinkExternal to="https://samharris.org/podcast/">
          Waking Up podcast
        </LinkExternal>
        .
      </p>
      <h2>Sam Harris' Books</h2>
      {books}
      <br />
      <h2>Political Views</h2>
      <p>
        Self described as a liberal, Sam Harris supports ideas like: increasing
        taxes for very wealthy people; same-sex marriage; the decriminalization
        of certain drugs.
      </p>
      <p>
        However, many liberals attack Harris for his critical views on Islam.
        Harris has engaged in many religious debates and discussions (find some
        on our <Link to={Routes.DEBATES}>debates page</Link>), for example with
        Ben Affleck and Cenk Uygur, who have both criticized him.
      </p>
      <p>
        He released a response regarding his controversial views on Islam. Part
        it of writes,
      </p>
      <blockquote>
        Is it really true that the sins for which I hold Islam accountable are
        "committed at least to an equal extent by many other groups, especially
        [my] own"? ... The freedom to poke fun at Mormonism is guaranteed [not
        by the First Amendment but] by the fact that Mormons do not dispatch
        assassins to silence their critics or summon murderous hordes in
        response to satire. ... Can any reader of this page imagine the staging
        of a similar play [to The Book of Mormon] about Islam in the United
        States, or anywhere else, in the year 2013? ... At this moment in
        history, there is only one religion that systematically stifles free
        expression with credible threats of violence. The truth is, we have
        already lost our First Amendment rights with respect to Islam—and
        because they brand any observation of this fact a symptom of
        Islamophobia, Muslim apologists like Greenwald are largely to blame.
      </blockquote>
      {quotesSection}
    </Layout>
  )
}

export default SamHarris
